import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-politica-seguridad',
  templateUrl: './politica-seguridad.component.html',
  styleUrls: ['./politica-seguridad.component.scss']
})
export class PoliticaSeguridadComponent implements OnInit {
  urlLogo = environment.recursos + '/nuevoestiloweb/logos/logo_tipos_destinux.svg';
  constructor() { }

  ngOnInit(): void {
  }

}
