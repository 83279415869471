<div class="policies-container">
  <!-- ES -->
   <div class="seccion-idioma spanish">
    <div class="container-logo">
      <img [src]="urlLogo" alt="logo-destinux" width="100%"
      height="auto">
    </div>
    <p>
      <span class="titulo">
        Política General  de Seguridad de la Información
      </span>
    </p>
    <p>En CONSULTIA BUSINESS TRAVEL, la seguridad es nuestra máxima prioridad. En aras de la apertura y la transparencia, estas son algunas de las medidas de seguridad que tomamos para proteger y defender las plataformas de CONSULTIA BUSINESS TRAVEL y proteger todos los datos que manejamos. </p>
    <p>El equipo directivo de Consultia Business Travel promueve la implantación, mantenimiento y mejora continua de su Sistema de Gestión de Seguridad de la Información basado en los requisitos de las normas de referencia UNE-ISO/IEC 27001. Consultia Business Travel se compromete a respetar la privacidad de todas las partes interesadas y a proteger los datos relativos a estas partes interesadas de terceros. Con este fin, el liderazgo y la gerencia de Consultia Business Travel se comprometen a mantener un entorno seguro en el que procesar datos para que Consultia Business Travel pueda cumplir con estas promesas.  </p>
    <p>A continuación, se exponen las bases que servirán de pilares para alcanzar y garantizar la eficacia del Sistema de Gestión de Seguridad de la Información para la organización:  </p>
    <ul>
      <li>Alto compromiso con el cliente con el objetivo de garantizar un servicio ininterrumpido, con una gestión rápida y adecuada de los incidentes de Seguridad de la Información. El Plan de Continuidad del Negocio y el Plan de Respuesta a Incidentes son los pilares de la ISO, y estos procesos están en marcha en Consultia Business Travel.</li>
      <li>Se cumplen y mantienen los requisitos legales de los clientes relacionados con la seguridad de la información y los datos. Nuestra gobernanza de seguridad de la información evalúa dichos requisitos y contribuye a cumplirlos. </li>
      <li>Crear conciencia sobre las políticas existentes en materia de seguridad y privacidad a través de una plataforma.  Dar especial énfasis en las capacitaciones derivadas de los Sistemas de Gestión de Seguridad de la Información implantados en la organización y asegurar la participación activa del personal de Consultia Business Travel respecto a dichas capacitaciones que se realizan internamente de manera periódica para establecer y cumplir los objetivos y metas relacionados con la Seguridad de la Información.  </li>
      <li>Llevamos a cabo capacitaciones específicas para nuestros equipos y recursos sobre phishing, smishing, vishing, ingeniería social y otros aspectos de la seguridad de la información.  </li>
      <li>Aportando valor añadido al cliente utilizando tecnologías actualizadas, creando soluciones, asegurando la mejora continua de los procesos existentes y realizando una investigación constante hacia la innovación para asegurarnos de estar siempre por delante de cualquier problema de seguridad que pueda surgir. </li>
      <li>Los procesos y tecnologías actualizados que utilizamos en Consultia Business Travel incluyen SSDLC (Ciclo de vida de desarrollo de software seguro), Cifrado, BCP (Plan de Continuidad del Negocio), DRP (Plan de Recuperación de Desastres), Paneles de Seguridad, Políticas de Respaldo, entre otros. </li>
      <li>Contamos con un Ciclo de Vida de Desarrollo de Software Seguro para construir nuevo software de manera organizada y robusta. Este proceso también garantiza que contamos con controles para minimizar el riesgo en caso de fuga de datos. </li>
      <li>Gestionar la prestación de los servicios que realiza Consultia Business Travel a los clientes de forma eficaz y eficiente dentro de un ciclo de vida que permita la mejora continua de los procesos implantados. </li>
      <li>Garantizar la confidencialidad, integridad y disponibilidad de la información mediante el análisis de nuestros riesgos. Los miramos desde las tres dimensiones. Están siempre presentes en nuestros procesos, y buscamos que toda la información que gestiona nuestra empresa cumpla con las tres dimensiones. </li>
      <li>La Política Corporativa del Sistema de Gestión de Seguridad de la Información constituye el marco de referencia para el establecimiento de los objetivos del SGSI de tal manera que se garantice la mejora continua en el rendimiento. </li>
      <li>Debido a la preocupación por la Seguridad de la Información, Consultia Business Travel realiza un análisis de riesgos que se actualiza constantemente para mantener el control sobre posibles nuevas situaciones de riesgo y el establecimiento del plan correspondiente para el tratamiento de los riesgos no aceptados. A partir de los resultados obtenidos en la fase de planificación y análisis de inteligencia de amenazas, se implementan controles de seguridad y se adaptan los procedimientos del sistema de gestión a los requerimientos del proceso. </li>
      <li>Además, el equipo de liderazgo de Consultia Business Travel verifica el impulso del sistema, realiza análisis de datos e implementa la toma de decisiones asegurando la disponibilidad de recursos y la intercomunicación entre todos los departamentos del organigrama de la empresa. También influye de manera proactiva y positiva en el comportamiento de sus grupos de interés y proveedores y contratistas clave al promover la adopción de comportamientos responsables de seguridad de la información.  </li>
      <li>Se evalúan las mejoras, y una vez que se evalúa su viabilidad, se implementan, operan y mantienen. Todo el Sistema de Gestión de Seguridad de la Información se basa en un ciclo de mejora continua que incluye la planificación de sus actividades, su implantación, funcionamiento, revisión y posterior mejora.</li>
      <li>Las mejoras a esta política y a los sistemas de gestión subyacentes se establecen durante las fases de revisión y mejora sobre la base de las aportaciones recibidas de las partes interesadas y el personal interno y externo.  </li>
    </ul>
    <p>Esta política se comunica a todos los empleados de Consultia Business Travel. Todos los empleados de Consultia Business Travel están obligados a cumplir con las normas y procedimientos derivados de esta política.</p>
   </div>
  <!-- EN -->
   <div class="seccion-idioma english">
    <div class="container-logo">
      <img [src]="urlLogo" alt="logo-destinux" width="100%"
      height="auto">
    </div>
    <p>
      <span class="titulo">
        General Information Security Policy
      </span>
    </p>
    <p>At CONSULTIA BUSINESS TRAVEL, security is our top priority. For the sake of openness and transparency, these are some of the security measures we take to protect and defend CONSULTIA BUSINESS TRAVEL platforms and protect all the data we handle. </p>
    <p>Consultia Business Travel’s leadership team promotes the implementation, maintenance and continuous improvement of its Information Security Management System based on the requirements of the reference standards UNE-ISO/IEC 27001. Consultia Business Travel is committed to respecting the privacy of all interested parties and to protecting the data relating to these interested parties from third parties. To this end, Consultia Business Travel leadership and management are committed to maintaining a secure environment in which to process data so that Consultia Business Travel can meet these promises.  </p>
    <p>The bases that will serve as pillars to achieve and guarantee the effectiveness of the Information Security Management System for the organization are set out below:  </p>
    <ul>
      <li>High commitment to the customer with the aim of guaranteeing an uninterrupted service, with rapid and appropriate management of Information Security incidents. Business Continuity Plan & Incident Response Plan are the pillars of the ISO, and such processes are in place at Consultia Business Travel.  </li>
      <li>Legal requirements of clients pertaining to the information and data security are met and maintained. Our Information Security governance assesses such requirements and contributes to meeting those requirements. </li>
      <li>Creating awareness about existing policies regarding security and privacy through a platform.  Giving special emphasis on trainings derived from the Information Security Management Systems implemented in the organization and ensuring active participation by Consultia Business Travel staff in respect of such trainings that are conducted internally on a regular basis to establish and meet the objectives and goals related to Information Security.  </li>
      <li>We conduct specific trainings for our teams and resources on phishing, smishing, vishing, social engineering and other aspects of information security.  </li>
      <li>Providing added value to the client using updated technologies, creating solutions, ensuring continuous improvement in the existing processes and conducting steady research towards innovation to ensure we are always ahead of any security issues that may arise. </li>
      <li>The updated process and technologies we use at Consultia Business Travel includes SSDLC (Secure Software Development Lifecycle), Encryption, BCP (Business Continuity Plan), DRP (Disaster Recovery Plan), Security Dashboards, Backup policies amongst others. </li>
      <li>We have a Secure Software Development Life Cycle to build new software in an organised and robust manner. This process also ensures that we have controls in place to minimise the risk in case of any data leakage. </li>
      <li>Managing the provision of services carried out by Consultia Business Travel to clients in an effective and efficient manner within a life cycle that allows the continuous improvement of the processes implemented. </li>
      <li>Ensuring the confidentiality, integrity, and availability of the information by analysing our risks. We look at them from all three dimensions. They are always present in our processes, and we seek to ensure that all information managed by our company complies with all three dimensions.</li>
      <li>Corporate Policy of Information Security Management System constitutes to be the reference framework for the establishment of goals of ISMS in such a way as to ensure continuous improvement in performance. </li>
      <li>Because of the concern for Information Security, Consultia Business Travel carries out a risk analysis that is constantly updated to maintain control over possible new risk situations and the establishment of the corresponding plan for the treatment of unaccepted risks. Based on the results obtained in the planning phase and threat intelligence analysis, security controls are implemented, and the procedures of the management system are adapted to the requirements of the process. </li>
      <li>In addition, the Consultia Business Travel leadership team checks the impulse of the system, conducts data analysis and implements decision making by ensuring the availability of resources and intercommunication between all departments of the company's organization chart. It also proactively and positively influences the behaviour of its stakeholders and key suppliers and contractors by promoting the adoption of responsible information security behaviours.</li>
      <li>Improvements are evaluated, and once their feasibility is assessed, they are implemented, operated and maintained. The entire Information Security Management System is based on a continuous improvement cycle that includes the planning of its activities, its implementation, operation, review and subsequent improvement.</li>
      <li>Improvements to this policy and the underlying management systems are established during the review and improvement phases based on inputs received from internal and external stakeholders/ personnel.</li>
    </ul>
    <p>This policy is communicated to all Consultia Business Travel employees. All Consultia Business Travel employees are required to comply with the standards and procedures derived from this policy.</p>
   </div>
</div>